import React from 'react';
import Video from './Video';

function App() {
  return (
    <div className="App">
      <Video />
    </div>
  );
}

export default App;
